import React, { FunctionComponent, useState } from "react";
import {
  Button,
  Form,
  Modal,
  Select,
  Table,
  useFormValues,
} from "@administrate/piston-ux";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import { FULFIL_OBJECTIVE } from "../../mutations/learningPaths";
import { useLmsMutation, useLmsQuery } from "../../hooks/lms";
import {
  COURSE_BOOKABLE_EVENTS,
  COURSE_NON_BOOKABLE_EVENTS,
} from "../../queries/learningPaths";
import { extractNodes } from "../../utils/extractNodes";
import { DeliveryMethod, Event, Maybe } from "../../generated/lmsTypes";
import { useTranslation } from "react-i18next";
import { getEventStartDate } from "../../utils/dateTimeHelpers";
import { TFunction } from "i18next";

export const LearningPathRegistrationModal: FunctionComponent<{
  show: boolean;
  onClose: () => void;
  courseTitle: string;
  learningPathRegistrationId: string;
  objectiveId?: string;
  courseCode: string;
}> = ({
  show,
  onClose,
  courseTitle,
  learningPathRegistrationId,
  objectiveId,
  courseCode,
}) => {
  const values = useFormValues({ location: "0" });
  const [fulfilObjective] = useLmsMutation(FULFIL_OBJECTIVE);
  const [selectedEventId, setSelectedEventId] = useState<Maybe<string>>(null);
  const { t } = useTranslation();

  return useObserver(() => {
    let wantedLocation = null;

    if (values.location && values.location !== "0") {
      wantedLocation = values.location;
    }

    const handleClose = async (submitted: boolean) => {
      if (submitted && selectedEventId) {
        await fulfilObjective({
          variables: {
            eventId: selectedEventId,
            objectiveId,
            learningPathRegistrationId,
          },
        });
      }
      setSelectedEventId(null);
      onClose();
    };

    return (
      <Modal
        title={t("registerOntoAnEvent")}
        show={show}
        onDone={handleClose}
        size="large"
      >
        <React.Fragment>
          <p>
            <b>{t("course")}: </b>
            {courseTitle}
          </p>
          <DisplayEventOptions
            courseCode={courseCode}
            wantedLocation={wantedLocation}
            values={values}
            onSelect={setSelectedEventId}
            selectedEventId={selectedEventId}
          />
        </React.Fragment>
      </Modal>
    );
  });
};

const DisplayEventOptions: React.FunctionComponent<{
  courseCode: string;
  wantedLocation: string;
  values: any;
  onSelect: Function;
  selectedEventId: Maybe<string>;
}> = ({ courseCode, wantedLocation, values, onSelect, selectedEventId }) => {
  const {
    data: dataBookable,
    error: errorBookable,
    loading: loadingBookable,
  } = useLmsQuery(COURSE_BOOKABLE_EVENTS, {
    variables: { courseCode: courseCode },
  });
  const {
    data: dataNonBookable,
    error: errorNonBookable,
    loading: loadingNonBookable,
  } = useLmsQuery(COURSE_NON_BOOKABLE_EVENTS, {
    variables: { courseCode: courseCode },
  });
  const { t } = useTranslation();

  if (loadingBookable || loadingNonBookable) return <div>Loading...</div>;
  if (errorBookable || errorNonBookable) return <div>Error!</div>;

  let wantedEvents = [];
  const locations = [
    {
      label: t("allLocations"),
      value: "0",
    },
  ];

  const allBookableEvents = extractNodes(dataBookable.events.edges);
  const allNonBookableEvents = extractNodes(dataNonBookable.events.edges);
  /************************************************************************
  Limiting allEvents to 50 in total as there is no pagination on this table
  and we don't want non bookable events to be shown under bookables when 
  not all bookable events are shown (if there are more than 50 bookable 
  events - the current default pagination limit)
  This should be removed when pagination has been implemented 
  *************************************************************************/
  const allEvents = allBookableEvents.concat(allNonBookableEvents).slice(0, 50);
  const locationNames = allEvents.map(event => event.location.name);
  const uniqueLocationNames: string[] = Array.from(new Set(locationNames));

  uniqueLocationNames.forEach(uniqueLocationName => {
    locations.push({
      label: uniqueLocationName,
      value: uniqueLocationName,
    });
  });

  if (wantedLocation) {
    wantedEvents = allEvents.filter(
      event => event.location.name === wantedLocation,
    );
  } else {
    wantedEvents = allEvents;
  }

  return (
    <React.Fragment>
      <Form values={values}>
        <Select name="location" options={locations} label={t("location")} />
      </Form>
      <DisplayEvents
        eventData={wantedEvents}
        onSelect={onSelect}
        selectedEventId={selectedEventId}
      />
    </React.Fragment>
  );
};

const getDuration = (event: Event, t: TFunction): string => {
  let duration: number | null = null;

  if (event.deliveryMethod === DeliveryMethod.Lms) {
    duration = event.accessDuration ?? null;
  } else if (event.classroomStart && event.classroomEnd) {
    const startDate = moment(event.classroomStart);
    const endDate = moment(event.classroomEnd);
    if (startDate.isValid() && endDate.isValid()) {
      const millisecondsInADay = 1000 * 60 * 60 * 24;
      duration = Math.ceil(endDate.diff(startDate) / millisecondsInADay);
    }
  }

  if (!duration || duration < 1) {
    return "";
  } else if (duration === 1) {
    return `${duration} ${t("day")}`;
  } else {
    return `${duration} ${t("days")}`;
  }
};

const getEventTime = (event: Event): string => {
  if (
    event.deliveryMethod === DeliveryMethod.Lms ||
    !event.start ||
    !event.end ||
    !event.timeZoneName
  ) {
    return "-";
  }

  const startDate = moment(event.start).tz(event.timeZoneName);
  const endDate = moment(event.end).tz(event.timeZoneName);
  if (
    !startDate.isValid() ||
    !endDate.isValid() ||
    !startDate.isBefore(endDate)
  ) {
    return "-";
  }

  return `${startDate.format("LT")} - ${endDate.format(
    "LT",
  )} ${startDate.zoneName()}`;
};

const formatEventForDisplay = (event: Event, t: TFunction) => ({
  idAndBookable: {
    id: event.id,
    isBookable:
      event.remainingPlaces == null ? "true" : event.remainingPlaces > 0,
  },
  location: event.location?.name,
  startDate: getEventStartDate(event, t),
  duration: getDuration(event, t),
  time: getEventTime(event),
  placesRemaining: event.remainingPlaces ?? t("unlimited"),
});

const DisplayEvents: React.FunctionComponent<{
  eventData: Event[];
  onSelect: Function;
  selectedEventId: Maybe<string>;
}> = ({ eventData, onSelect, selectedEventId }) => {
  const { t } = useTranslation();
  const FulfillObjectiveButton: React.FunctionComponent<{
    id: string;
    isBookable: Boolean;
  }> = ({ id: eventId, isBookable }) =>
    isBookable ? (
      <Button
        disabled={!isBookable}
        onClick={() => onSelect(eventId)}
        label={eventId === selectedEventId ? t("selected") : t("select")}
        type={eventId === selectedEventId ? "primary" : "default"}
      />
    ) : (
      <span>Full</span>
    );

  return (
    <React.Fragment>
      <Table
        headings={[
          {
            title: t("location"),
            key: "location",
          },
          {
            title: t("startDate"),
            key: "startDate",
          },
          {
            title: t("duration"),
            key: "duration",
          },
          {
            title: t("time"),
            key: "time",
          },
          {
            title: t("placesRemaining"),
            key: "placesRemaining",
          },
          {
            title: "",
            key: "idAndBookable",
            formatter: props => (<FulfillObjectiveButton {...props} />) as any,
          },
        ]}
        data={eventData.map((event: Event) => formatEventForDisplay(event, t))}
      />
    </React.Fragment>
  );
};
